import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import questionsData from '../../data/questions.json'; // Import the JSON file
import './PrepareForInterview.css';

const PrepareForInterview = () => {
  const [categories, setCategories] = useState({});
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false once auth check is done
    });

    // Load the questions from the JSON file
    setCategories(questionsData);
  }, []);

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  if (loading) {
    // Show a loading message while checking authentication
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <div className="login-required-message">
        <span>Login required for this page</span>
        <button onClick={handleLogin} className="btn2 btn-outline-primary ms-3">
          Login
        </button>
      </div>
    );
  }

  return (
    <div className="container interviewContainer mt-5">
      <h2 className="text-center mb-4">Prepare for Your Interview</h2>
      {Object.entries(categories).map(([category, questions], catIndex) => (
        <div key={catIndex} className="mb-5">
          <h3 className="text-secondary">{category}</h3> {/* Category Title */}
          <ul className="list-group">
            {Object.entries(questions).map(([question, url], index) => (
              <li key={index} className="list-group-item">
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-primary">
                  {question}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default PrepareForInterview;
